import request from '@/utils/request'


export const saveOrUpdateUserScheduling: any = (data: any) => {
    return request({
        url: `/userScheduling`,
        method: 'post',
        data
    })
}

export const deleteUserScheduling: any = (id: string) => {
    return request({
        url: `/userScheduling/${id}`,
        method: 'delete'
    })
}

export const listUserScheduling: any = (data: any) => {
    return request({
        url: `/userScheduling/list`,
        method: 'post',
        data
    })
}
export const getSchedulingWithDentistIdAndClinicId: any = (data: any) => {
    return request({
        url: `/userScheduling/${data.dentistId}/${data.clinicId}`,
        method: 'get',
    })
}